@import './variables.scss';

$borderColor: rgba(#000000, 0.15);
@mixin lines() {
  border-left: 1px solid $borderColor;
  border-right: 1px solid $borderColor;
  width: 1180px;
  height: 100%;
  margin: 0 auto;
  position: fixed;
  top: 0;
  left: calc(50% - (1180px / 2));

  @media screen and (max-width: 767px) {
    width: calc(100% - 50px);
    margin: 0 25px;
    left: 0;
  }

  & > div {
    border-left: 1px solid $borderColor;
    border-right: 1px solid $borderColor;
    width: 50%;
    margin: 0 auto;
    height: 100%;
    @media screen and (max-width: 767px) {
      width: 33.333%;
    }

    & > div {
      border-left: 1px solid $borderColor;
      width: 0;
      margin: 0 auto;
      height: 100%;
      @media screen and (max-width: 767px) {
        display: none;
      }
    }
  }
}
