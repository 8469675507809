@import '../common/function.scss';

.l-header {
  display: flex;
  position: fixed;
  left: calc(50% - (1280px / 2));
  width: 1280px;
  z-index: 10;
  margin-top: 16px;
  margin-bottom: 16px;
  @media screen and (max-width: 767px) {
    left: 0;
    width: 100%;
  }

  &__container {
    display: flex;
    justify-content: center;
    margin: 0px auto;
    width: 1226px;
  }

  &__logo {
    display: block;
  }

  &__nav {
    align-items: center;
    display: flex;
    margin-top: 2px;
    margin-left: 55px;

    .page-link {
      align-items: center;
      background-color: #fff;
      border-radius: 30px;
      display: flex;
      // flex-wrap: wrap;
      justify-content: space-between;
      padding: 0 30px;
      width: 672px;

      &__item {
        font-size: 13px;
        font-weight: 700;
        padding: 15px 0;
        position: relative;
        @media screen and (max-width: 767px) {
          padding: 0;
        }
        .active-bar {
          height: 3px;
          background-color: #00ccaf;
          width: 100%;
          position: absolute;
          bottom: 0;
        }

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
      }
    }

    .option-link {
      align-items: center;
      display: flex;
      // flex-wrap: wrap;
      margin: 0px 0px 0px 21px;

      &__item {
        align-items: center;
        background: #00ccaf;
        border-radius: 30px;
        color: #fff;
        display: flex;
        font-size: 14px;
        font-weight: 700;
        height: 45px;
        justify-content: center;
        width: 164px;

        &:hover {
          cursor: pointer;
          opacity: 0.7;
        }
        .active-bar {
          height: 3px;
          background-color: #00ccaf;
          width: 100%;
          position: absolute;
          bottom: 0;
        }

        &:not(:first-child) {
          margin-left: 20px;
        }
      }
    }
  }

  @media screen and (max-width: 767px) {
    display: block;
    margin: 0;

    &__container,
    &__nav {
      display: block;
    }

    &__container {
      padding: 9px 12px;
      width: 100%;

      &.is-open {
        background: #fff;
        height: 100vh;

        .l-header__nav {
          margin-left: unset;
          margin-top: 37px;
          opacity: 1;
          display: block;
        }

        .btn-hmb {
          border-top: #fff;
          top: 15px;

          &:before {
            transform: rotate(45deg);
            transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1);
            right: 1px;
            top: 8px;
            width: 115%;
          }

          &:after {
            bottom: unset;
            right: 0;
            top: 8px;
            transform: rotate(-45deg);
            width: 115%;
            transition: transform 0.4s cubic-bezier(0.16, 1, 0.3, 1);
          }

          &__line {
            opacity: 0;
          }
        }
      }
    }

    &__logo {
      display: inline-block;
      width: 93px;

      .img {
        width: 100%;
      }
    }

    &__nav {
      margin-top: 0px;
      opacity: 0;
      display: none;

      .page-link {
        background: none;
        border-radius: 0;
        display: block;
        padding: 0 11px;
        width: 100%;

        &__item {
          font-size: 28px;

          &:not(:first-child) {
            margin-top: 18px;
          }
        }
      }

      .option-link {
        display: block;
        margin: 24px 0 0;
        padding: 0 8px;

        &__item {
          font-size: 19px;
          height: 60px;
          width: 245px;

          &:not(:first-child) {
            margin-left: 0;
            margin-top: 16px;
          }
        }
      }
    }

    .btn-hmb {
      align-items: center;
      display: flex;
      height: 24px;
      justify-content: center;
      position: absolute;
      right: 10px;
      top: 14px;
      width: 30px;

      &:before,
      &:after {
        background: #000;
        content: '';
        position: absolute;
        height: 3px;
        width: 100%;
      }

      &:before {
        top: 0;
      }

      &:after {
        bottom: 1px;
      }

      &__line {
        background: #000;
        display: block;
        height: 3px;
        position: absolute;
        top: 10px;
        width: 100%;
      }
    }
    .is-white {
      &:before,
      &:after {
        background: #ffffff;
      }
      &__line {
        background: #ffffff;
      }
    }
  }
}
