@import './variables.scss';

*::-webkit-scrollbar {
  display: none;
}

html,
body {
  overflow: hidden;
}

.picture-story {
  & > section {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow-y: auto;
    opacity: 0;
    &.prev {
      z-index: 1;
    }
    &.active {
      z-index: 2;
      opacity: 0;
      &.enter {
        transition: opacity 300ms;
        opacity: 1;
      }
    }
    &.initial {
      z-index: 1;
      opacity: 1;
    }
    &:nth-child(1) {
      &.prev {
        opacity: 1;
      }
      &.active {
        opacity: 1;
      }
    }
    &:nth-child(2) {
      &.active {
        opacity: 1;
      }
    }
  }
}
