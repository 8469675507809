@import './function.scss';
@import './variables.scss';

html {
  overflow: auto;
}

body {
  background-color: #eff2f5;
  font-feature-settings: “palt”;
}

.btn-hover {
  opacity: 1;
  transition: opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  cursor: pointer;
  &:hover {
    opacity: 0.6;
    transition: opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);
  }
}
