.cases {
  padding-top: 156px;
  @media screen and (max-width: 767px) {
    padding-top: 46.5px;
  }

  &-inner {
    margin: 0px auto;
    text-align: center;
    padding-bottom: 133px;

    @media screen and (max-width: 767px) {
      width: 100%;
      padding-bottom: 76.5px;
    }
  }

  .title {
    display: inline-block;
    color: #ffffff;
    font-size: 45px;
    line-height: 0.7;
    letter-spacing: 1.8px;
    text-align: center;
    font-weight: 800;

    @media screen and (max-width: 767px) {
      display: none;
    }
    &::after {
      content: '';
      display: block;
      background: #7fe5d7;
      height: 3px;
    }
  }
  .slide-area {
    margin-top: 41px;

    @media screen and (max-width: 767px) {
      margin-top: 39px;
    }

    .slider {
      &:not(:first-child) {
        margin-top: 40px;

        @media screen and (max-width: 767px) {
          margin-top: 24px;
        }
      }

      &:last-child {
        @media screen and (max-width: 767px) {
          margin-top: 34px;
        }

        .cases-slider {
          .control {
            @media screen and (max-width: 767px) {
              margin-top: 12px;
            }
          }
        }
      }
    }
  }
}
