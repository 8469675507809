@import '../common/function.scss';
@import '../common/variables.scss';

.l-lg {
  display: block !important;

  @media screen and (max-width: 767px) {
    display: none !important;
  }
}

.l-sm {
  display: none !important;

  @media screen and (max-width: 767px) {
    display: block !important;
  }
}

.item-business {
  align-items: center;
  display: flex;
  justify-content: center;
  //margin-top: 50px;

  @media screen and (max-width: 767px) {
    height: unset;
    padding-top: 80px;
    margin-bottom: 80px;
    display: block;
  }

  .l-container {
    display: block;
    margin-top: 74px;
    width: 1182px;

    @media screen and (max-width: 767px) {
      padding: 26px;
      width: 100%;
      padding-top: 0;
      margin-top: 26px;
      margin-top: 0;
    }
  }

  .business-list {
    display: block;
    width: 100%;

    &__item {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        display: block;
      }

      &:not(:first-child) {
        margin-top: 20px;

        @media screen and (max-width: 767px) {
          margin-top: 22px;
        }
      }

      &:first-child {
        .business-list__item--img {
          .img-box {
            width: 519px;
          }
        }
      }

      &:last-child {
        .business-list__item--img {
          .img-box {
            height: 143px;
            padding: 1px 0px;
            width: 472px;

            @media screen and (max-width: 767px) {
              height: auto;
            }
          }
        }
      }

      &--text,
      &--img {
        display: block;
      }

      &--text {
        width: 584px;

        @media screen and (max-width: 767px) {
          width: 100%;
        }

        .title-block,
        .text-box {
          background: $white;
          display: block;
          width: 100%;
        }

        .title-block {
          align-items: center;
          display: flex;
          height: 92px;
          padding: 5px 40px 0;

          @media screen and (max-width: 767px) {
            height: auto;
            padding: 25px 23px 20px;
          }

          .title {
            color: #000;
            font-size: 30px;
            font-weight: 700;
            line-height: 1;
            width: fit-content;

            .l-lg {
              background: linear-gradient(transparent 84%, $t-green 84%);
              margin-bottom: 2px;
              z-index: 2;
            }

            .l-sm {
              @media screen and (max-width: 767px) {
                background: linear-gradient(transparent 90%, $t-green 84%);
                background-position: 100% 83%;
                background-size: 100% 46px;
                display: inline;
                line-height: 42.5px;
                width: fit-content;
              }
            }
          }
        }

        .text-box {
          height: 182px;
          margin-top: 6px;
          padding: 31px 41px 24px;

          @media screen and (max-width: 767px) {
            height: auto;
            padding: 25px 9px 20px 23px;
          }

          .text {
            font-size: 16px;
            line-height: 2;

            @media screen and (max-width: 767px) {
              font-size: 14px;
            }
          }
        }
      }

      &--img {
        align-items: center;
        background: $white;
        display: flex;
        justify-content: center;
        padding: 30px;
        width: 594px;

        @media screen and (max-width: 767px) {
          margin-top: 6px;
          padding: 24px 16px 18px 16px;
          width: 100%;
        }

        &.second {
          .img-box {
            width: 470px;
          }

          @media screen and (max-width: 767px) {
            padding: 24px 25px 32px 30px;

            .img-box {
              width: 90%;
            }
          }
        }

        .img-box {
          display: block;
          width: 100%;

          &__img {
            display: block;
            height: auto;
            overflow: hidden;
            width: 100%;

            .img {
              width: 100%;
            }
          }
        }
      }
    }
  }
  .text-link {
    font-weight: bold;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
