@import './variables.scss';
@import './lines.scss';

.background {
  position: fixed;
  width: 100%;
  height: 100%;
  transition: background-color 300ms ease;
  text-align: center;
  &-light {
    background: $bg;
  }
  &-dark {
    background: $bg_dark;
    & div {
      border-color: rgba(#fff, 0.15) !important;
    }
  }
  & > div {
    @include lines;
  }
  canvas {
    width: 100%;
    height: 100%;
  }
}
