@import '../../styles/common/variables.scss';

.contact {
  padding-top: 80px;

  &-inner {
    margin: 0 auto;

    @media screen and (max-width: 767px) {
      padding: 0 26px;
      width: 100%;
    }

    .title {
      display: block;
      margin-top: 109px;
      text-align: center;

      @media screen and (max-width: 767px) {
        margin-top: 0;
      }

      .contents-title {
        color: #ffffff;
        display: inline-block;
        font-size: 60px;
        text-align: center;
        font-weight: 700;
        letter-spacing: 2.4px;
        line-height: 0.55;
        position: relative;
        z-index: 1;

        @media screen and (max-width: 767px) {
          font-size: 37px;
        }

        &::after {
          content: '';
          display: block;
          background: #7fe5d7;
          height: 5px;
          left: 10px;
          position: absolute;
          width: 93.5%;
          z-index: -1;

          @media screen and (max-width: 767px) {
            height: 4px;
          }
        }
      }

      .description {
        color: #fff;
        margin-top: 38px;
        font-size: 15px;
        font-weight: bold;
        letter-spacing: 0.001em;

        @media screen and (max-width: 767px) {
          margin-top: 20px;
        }
      }
    }

    .contents-area {
      width: 842px;
      margin: 36px auto 0;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 3px;

      @media screen and (max-width: 767px) {
        display: block;
        width: 100%;
      }

      .list {
        @media screen and (max-width: 767px) {
          &:first-child {
            .contact-item .item .title-area {
              padding: 11px 4px 10px 15px;
            }

            /*.contact-item .item-title {
              white-space: pre-wrap;
            }*/
          }

          &:first-child {
            .contact-item .item-num {
              span {
                &:last-child {
                  margin-left: 5px;
                }
              }
            }
          }

          &:nth-child(2) {
            .contact-item .item-num {
              span {
                &:last-child {
                  margin-left: -1px;
                }
              }
            }
          }

          &:nth-child(3) {
            .contact-item .item-num {
              span {
                &:last-child {
                  margin-left: -1px;
                }
              }
            }
          }

          &:nth-child(4) {
            .contact-item .item .title-area {
              padding: 10px 4px 10px 10px;
            }

            .contact-item .item-title {
              white-space: pre-wrap;
              padding-left: 4px;
              padding-top: 0px;
            }

            .contact-item .item-num {
              span {
                &:last-child {
                  margin-left: -2px;
                }
              }
            }
          }


          &:last-child {
            .contact-item .item-num {
              span {
                &:last-child {
                  margin-left: -2px;
                }
              }
            }
          }

          &:not(:last-child) {
            margin-bottom: 5px;
          }
        }
      }
    }

    .form-area {
      margin: 75px auto 0;
      width: fit-content;
      padding-bottom: 157px;

      @media screen and (max-width: 767px) {
        display: block;
        margin: 70px auto 0;
        padding-bottom: 70px;
        width: 100%;
      }

      .category {
        display: flex;
        align-items: center;
        justify-content: space-between;
        line-height: 45px;
        width: 174px;

        @media screen and (max-width: 767px) {
          justify-content: unset;
          line-height: 30px;
          width: 100%;
        }

        &-text-area {
          height: 45px;

          @media screen and (max-width: 767px) {
            height: 20px;
          }
        }

        p {
          color: $white;
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.045em;
        }

        .batch {
          display: block;
          width: 40px;
          height: 20px;
          font-family: HiraKakuProN-W3;
          background-color: #ea352d;
          color: $white;
          font-size: 12px;
          border-radius: 2px;
          line-height: 20px;
          text-align: center;

          @media screen and (max-width: 767px) {
            margin-left: 9px;
          }
        }
      }

      .input-area {
        display: flex;

        @media screen and (max-width: 767px) {
          display: block;
          width: 325px;
          margin: 0 auto;
        }

        &:not(:first-child) {
          margin-top: 15px;
        }

        &:nth-child(2) {
          @media screen and (max-width: 767px) {
            margin-top: 6px;
          }
        }

        &:nth-child(3) {
          @media screen and (max-width: 767px) {
            margin-top: 4px;
          }
        }

        &:nth-child(4) {
          @media screen and (max-width: 767px) {
            margin-top: 5px;
          }
        }

        .input {
          margin-left: 16px;
          width: 590px;
          padding-left: 17px;
          background: $white;
          font-size: 14px;
          font-weight: 500;
          letter-spacing: 1.12px;
          line-height: 45px;

          @media screen and (max-width: 767px) {
            height: 50px;
            margin: 0;
            width: 100%;
          }
        }
        .has-error {
          background: rgb(249, 195, 193);
        }
        .error {
          font-size: 14px;
          font-weight: bold;
          padding-left: 1em;
          color: #ea352d;
          margin-top: 10px;
          margin-bottom: -5px;
          @media screen and (max-width: 767px) {
            padding-left: 0;
            margin-top: 5px;
            font-size: 12px;
          }
        }

        .select {
          position: relative;

          .icon {
            width: 33px;
            height: 33px;
            background-color: #00ccaf;
            position: absolute;
            top: 50%;
            right: 6px;
            transform: translateY(-50%);
            display: inline-flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 4px;

            @media screen and (max-width: 767px) {
              height: 40px;
              right: 5px;
              width: 40px;
            }

            &::before {
              content: '';
              display: inline-block;
              line-height: 1;
              width: 9px;
              height: 9px;
              border: 2px solid #ffffff;
              border-left: 0;
              border-bottom: 0;
              transform: rotate(135deg);
            }
          }
        }

        select {
          line-height: 45px;
        }

        .text-area {
          height: 180px;
          padding: 16px;
          line-height: 1.3;

          @media screen and (max-width: 767px) {
            height: 124px;
          }
        }
      }

      .send-btn {
        display: block;
        width: 303px;
        margin: 25px auto 0;
        background-color: #15c6ad;
        color: $white;
        font-size: 16px;
        font-weight: bold;
        line-height: 60px;
        border-radius: 30px;
        text-align: center;
        letter-spacing: 2.24px;

        @media screen and (max-width: 767px) {
          margin: 15px auto 0;
          width: 253px;
        }
      }
    }
  }
}
