@import '../common/function.scss';
@import '../common/variables.scss';

.item-news {
  align-items: center;
  display: flex;
  justify-content: center;

  @media screen and (max-width: 767px) {
    display:block;
  }

  .l-container {
    display: block;
    width: 778px;

    @media screen and (max-width: 767px) {
      padding: 80px 26px;
      width: 100%;
    }
  }

  &__title {
    display: block;
    margin: 0 auto;
    width: fit-content;

    .title {
      background: linear-gradient(transparent 84%, $t-green 84%);
      color: #000;
      font-size: 46px;
      font-weight: 700;
      letter-spacing: 0.025em;
      line-height: 0.9;

      @media screen and (max-width: 767px) {
        background: linear-gradient(transparent 90%, #7fe5d7 90%);
        font-size: 38px;
      }
    }

    .caption {
      color: #000;
      font-size: 16px;
      font-weight: 700;
      margin-top: 20px;
      text-align: center;

      @media screen and (max-width: 767px) {
        margin-top: 14px;
      }
    }
  }

  .news-list {
    display: block;
    margin-top: 40px;

    @media screen and (max-width: 767px) {
      margin-top: 24px;
      width: 100%;
    }

    &__item {
      background: $white;
      display: block;
      width: 100%;

      &:not(:first-child) {
        margin-top: 10px;

        @media screen and (max-width: 767px) {
          margin-top: 2px;
        }
      }

      &--box {
        .item-title {
          align-items: center;
          display: flex;
          min-height: 70px;
          justify-content: space-between;
          padding-right: 13px;
          padding-left: 30px;
          width: 100%;

          &.title-open {
          }

          &.title-close {

            .title {
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              display: -webkit-box;
            }
          }

          @media screen and (max-width: 767px) {
            align-items: flex-start;
            padding: 21px 25px 0 24px;
          }

          .title {
            font-size: 18px;
            font-weight: 700;
            line-height: 1.5;

            @media screen and (max-width: 767px) {
              width: 80%;
            }
          }

          .icon {
            align-items: center;
            background: #00ccaf;
            display: flex;
            height: 40px;
            justify-content: center;
            position: relative;
            width: 40px;
            z-index: 100;
            cursor: pointer;

            @media screen and (max-width: 767px) {
              margin-top: 7px;
            }

            &:before,
            &:after {
              background: $white;
              content: '';
              position: absolute;
            }

            &:before {
              height: 2px;
              width: 13px;
            }

            &:after {
              height: 13px;
              width: 2px;
            }
            &-open {
              &::after {
                display: none;
              }
            }
          }
        }

        .text-box {
          display: block;
          margin-top: -10px;
          padding-bottom: 20px;
          padding-left: 30px;
          width: 681px;
          overflow: hidden;

          @media screen and (max-width: 767px) {
            margin-top: 0;
            padding: 19px 25px 20px;
            width: 100%;
          }

          .text {
            color: #000;
            font-size: 14px;
            letter-spacing: 1.4px;
            line-height: 2;

            @media screen and (max-width: 767px) {
              line-height: 1.79;
            }

            a {
              word-break: break-all;
              font-weight: 700;
              &:hover {
                text-decoration: underline;
              }
            }
          }

          &_open {
          }
          &_close {
            max-height: 0;
            padding-bottom: 0;
            margin-top: 0;
            transition: max-height 0.2s cubic-bezier(0.16, 1, 0.3, 1);
          }
        }
      }
    }
  }

  &__all {
    display: flex;
    justify-content: flex-end;
    margin-top: 16px;

    @media screen and (max-width: 767px) {
      margin-top: 13px;
    }

    .link-all {
      color: #000;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.025em;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }
}
