@import '../../styles/common/variables.scss';

.contact-item {
  .item {
    background-color: $white;
    color: #000000;

    .title-area {
      padding: 13px 12px 14px 15px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 767px) {
        padding: 10px 4px 10px 17px;
      }

      .titles {
        @media screen and (max-width: 767px) {
          display: flex;
          align-items: center;
        }
      }
    }

    &-num {
      display: inline-block;
      font-weight: 700;
      font-size: 34px;
      letter-spacing: 0.15em;

      @media screen and (max-width: 767px) {
        font-size: 40px;
        letter-spacing: 0.015em;
        line-height: 1;
      }
    }

    &-title {
      display: inline-block;
      font-size: 18px;
      font-weight: bold;
      letter-spacing: 1.8px;
      padding-left: 11px;
      position: relative;

      @media screen and (max-width: 767px) {
        padding-left: 4px;
        padding-top: 5px;
        position: unset;
      }
    }

    .toggle-btn {
      width: 40px;
      height: 40px;
      background-color: #00ccaf;
      float: right;
      cursor: pointer;
      position: relative;
      z-index: 100;

      &_close {
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          width: 12px;
          height: 2px;
          background: $white;
        }

        &::after {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          width: 2px;
          height: 12px;
          background: $white;
        }
      }

      &_open {
        position: relative;

        &::before {
          content: '';
          display: block;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translateY(-50%) translateX(-50%);
          width: 12px;
          height: 2px;
          background: $white;
        }

        &::after {
          display: none;
        }
      }
    }

    .text-area {
      width: 291px;
      margin-left: 58px;
      padding-top: 3px;

      @media screen and (max-width: 767px) {
        margin-left: 50px;
        padding-top: 0;
        width: 65%;
      }

      &_inner {
        overflow: hidden;
        transition: max-height 0.5s cubic-bezier(0.16, 1, 0.3, 1);

        &_open {
          max-height: 200px;

          @media screen and (max-width: 767px) {
            padding-bottom: 16px;
          }
        }

        &_close {
          padding-bottom: 0;
          max-height: 0;
        }
      }

      p {
        font-size: 14px;
        line-height: 1.71;
        text-align: justify;
        font-weight: 500;

        @media screen and (max-width: 767px) {
          line-height: 1.48;
        }
      }

      .contact-btn {
        display: block;
        margin-top: 13px;
        width: 123px;
        line-height: 24px;
        background-color: #00ccaf;
        font-size: 12px;
        text-align: center;
        color: $white;
        border-radius: 12px;
        font-weight: bold;
        margin-bottom: 32px;

        @media screen and (max-width: 767px) {
          margin-bottom: 0;
          margin-top: 4px;
        }
      }
    }
  }
}

/* 番号部分調整 */
.contact-inner {
  .contents-area {
    .list {

      &:nth-child(1) {
        .contact-item .item {

          .item-title {
            bottom: 4px;
            padding-left: 11px;

            @media screen and (max-width: 767px) {
              bottom: unset;
              padding-left: 4px;
              position: unset;
            }
          }

          .item-num {
            span{
              &:first-child {
                position: relative;
                left: 0px;

                @media screen and (max-width: 767px) {
                 left: unset;
                 position: unset;
               }
              }

              &:last-child {
                position: relative;
                left: 4px;

                @media screen and (max-width: 767px) {
                 left: unset;
                 position: unset;
               }
              }
            }
          }
        }
      }

      &:nth-child(2) {
        .contact-item .item {
          .title-area {
            padding-left: 13px;
          }

          .item-title {
            bottom: 5px;
            padding-left: 4px;

            @media screen and (max-width: 767px) {
              bottom: unset;
              padding-left: 4px;
              position: unset;
            }
          }
        }
      }

      &:nth-child(3) {
        .contact-item .item {
          .title-area {
            padding-left: 13px;
          }

          .item-title {
            bottom: 5px;
            padding-left: 6px;

            @media screen and (max-width: 767px) {
              bottom: unset;
              padding-left: 4px;
              position: unset;
            }
          }
        }
      }

      &:nth-child(4) {
        .contact-item .item {
           .item-num {
             position: relative;
             left: -4px;

             @media screen and (max-width: 767px) {
               left: unset;
               position: unset;
             }

             span {
               &:last-child {
                 position: relative;
                 left: -1px;

                 @media screen and (max-width: 767px) {
                  left: unset;
                  position: unset;
                }
               }
             }
           }

           .item-title {
             bottom: 4px;
             padding-left: 0;

            @media screen and (max-width: 767px) {
              bottom: unset;
              padding-left: 4px;
              position: unset;
            }
          }
        }
      }


      &:nth-child(5),
      &:nth-child(6) {
        .contact-item .item {
          .title-area {
            padding-left: 12px;
            padding-top: 11px;
          }

          .item-title {
            padding-left: 3px;
          }
        }
      }

      &:nth-child(5) {
        .contact-item .item {
          .item-num {
            span{
              &:last-child {
                position: relative;
                left: 1px;

                @media screen and (max-width: 767px) {
                 left: unset;
                 position: unset;
               }
              }
            }
          }

           .item-title {
            bottom: 4px;
            padding-left: 10px;

            @media screen and (max-width: 767px) {
              bottom: unset;
              padding-left: 4px;
              position: unset;
            }
          }
        }
      }


      &:nth-child(6) {
        .contact-item .item {
           .item-title {
            bottom: 4px;

            @media screen and (max-width: 767px) {
              bottom: unset;
              position: unset;
            }
          }
        }
      }

    }
  }
}
