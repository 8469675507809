@import '../common/function.scss';
@import '../common/variables.scss';

.l-sm {
  display: none !important;

  @media screen and (max-width: 767px) {
    display: block !important;
  }
}

.title-box {
  align-items: center;
  display: flex;
  justify-content: center;
  padding-top: 78px;

  @media screen and (max-width: 767px) {
    align-items: unset;
    width: 100%;
  }

  &__contents {
    display: block;

    @media screen and (max-width: 767px) {
      position: relative;
      top: 35%;
    }
  }
}
