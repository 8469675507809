@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';

body {
  font-family: 'Montserrat', 'Hiragino Kaku Gothic ProN', 'ヒラギノ角ゴ ProN W3',
    'ヒラギノ角ゴ Pro W3', 'Hiragino Kaku Gothic Pro', sans-serif;
}

.Top {
  .title-box {
    &__blue {
      .title,
      .subTitle {
        color: $white;
      }
    }

    &__gray {
      .title,
      .subTitle {
        color: $black;
      }
    }

    &__contents {
      .title {
        font-size: 52px;
        font-weight: 700;
        letter-spacing: 0.0195em;
        margin: 0px auto;
        position: relative;
        text-align: center;
        width: fit-content;
        z-index: 2;

        &:before {
          background: #7fe5d7;
          content: '';
          height: 4px;
          left: 4px;
          bottom: 10px;
          position: absolute;
          width: 97%;
          z-index: -1;
        }

        &.cases {
          &:before {
            left: -1px;
            width: 101%;
          }
        }

        &.portfolio {
          letter-spacing: 0.01em;
        }
      }

      .subTitle {
        font-size: 14px;
        font-weight: 700;
        line-height: 2;
        text-align: center;

        &.partners {
          margin-top: 22px;
        }

        &.portfolio {
          margin-top: 20px;
        }

        &.team,
        &.case {
          margin-top: 23px;
        }
      }
    }
  }

  .item-team {
    height: unset;

    .l-container {
      padding: 26px;
      width: 100%;
    }
  }
}
