.footer {
  background-color: #000000;
  color: #ffffff;

  .footer-inner {
    padding-top: 148px;
    padding-bottom: 133px;
    text-align: center;

    @media screen and (max-width: 767px) {
      padding-top: 75px;
      padding-bottom: 82px;
    }

    .btn-area {
      display: flex;
      justify-content: center;

      @media screen and (max-width: 767px) {
        display: block;
      }

      .btn {
        width: 300px;
        line-height: 66px;
        border: solid 2px #ffffff;
        border-radius: 40px;
        text-align: center;
        font-size: 18.5px;
        font-weight: bold;
        cursor: pointer;

        @media screen and (max-width: 767px) {
          width: 256px;
          line-height: 64px;
          border: solid 1px #ffffff;
          font-size: 16px;
          margin: 0 auto;
        }

        &:first-child {
          letter-spacing: -2px;

          @media screen and (max-width: 767px) {
            letter-spacing: unset;
          }
        }

        &:not(:first-child) {
          letter-spacing: -2px;
          margin-left: 40px;

          @media screen and (max-width: 767px) {
            letter-spacing: unset;
            margin: 18px auto 0;
          }
        }
      }
    }
    .footer-logo {
      margin: 60px auto 0;
      width: 123px;
      height: 50px;

      @media screen and (max-width: 767px) {
        margin: 54px auto 0;
        width: 117px;
        height: 47px;
      }

      img {
        width: 100%;
      }
    }
    .copy {
      display: inline-block;
      font-family: Helvetica;
      font-size: 10px;
      letter-spacing: 0.4px;
      margin: 9px auto 0;

      @media screen and (max-width: 767px) {
        letter-spacing: 0.039em;
        margin: 15px auto 0;
      }
    }
  }
}
