.cases-slider {
  padding-left: 50px;
  width: 1280px;
  margin: 0 auto;

  @media screen and (max-width: 767px) {
    width: auto;
    padding-left: 25px;
  }
  .slick-arrow {
    display: none !important;
  }
  .slick-slide {
    margin-left: 40px;
    width: 568px;

    @media screen and (max-width: 767px) {
      margin-left: 6px;
      width: 86vw;
    }
  }
  .slide-image {
    a {
      background: white;
      &:hover {
        img {
          opacity: 0.7;
        }
      }
      img {
        transition: opacity 0.5s cubic-bezier(0.16, 1, 0.3, 1);
      }
    }
    img {
      width: 568px;
      height: auto;
      object-fit: cover;
      border-radius: 8px;

      @media screen and (max-width: 767px) {
        border-radius: 4px;
        width: 323px;
      }
    }
  }
  .control {
    margin-top: 8px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 767px) {
      margin-top: 15px;
    }

    .btn-field {
      display: flex;
    }

    .btn {
      position: relative;
      display: block;
      background-color: #15c6ad;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      cursor: pointer;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        display: inline-block;
        width: 9px;
        height: 9px;
        border: 2px solid;
        border-color: transparent transparent #ffffff #ffffff;
      }
    }

    .preview {
      &::after {
        left: 50%;
        transform: rotate(45deg) translate(-50%);
      }
    }

    .next {
      margin-left: 10px;

      &::after {
        right: 50%;
        transform: rotate(225deg) translateY(50%);
      }
    }

    .bar-area {
      width: 87%;
      height: 1px;
      margin-left: 20px;
      @media screen and (max-width: 767px) {
        margin-left: 9.5px;
        width: 64%;
      }
    }

    .horizontal-bar {
      width: 100%;
      border-top: 1px solid #ffffff;
      opacity: 0.5;
    }

    .move-bar {
      display: block;
      width: 100%;
      height: 3px;
      background: #7fe5d7;
      transform: translateY(-1px);
      transition: transform 0.5s cubic-bezier(0.16, 1, 0.3, 1);

      @media screen and (max-width: 767px) {
        height: 1.5px;
      }
    }
  }
}
