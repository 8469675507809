@import '../common/function.scss';
@import '../common/variables.scss';

/* PARTNERS PORTFOLIO */
.item-common {
  align-items: center;
  display: flex;

  @media screen and (max-width: 767px) {
    display: block;
  }

  .l-container {
    align-items: center;
    display: flex;
    margin: 0px auto;
    width: 1010px;

    @media screen and (max-width: 767px) {
      display: block;
      padding-top: 80px;
      padding-bottom: 80px;
      width: 100%;
    }


    &.portfolio {
      @media screen and (max-width: 767px) {
      }
    }
  }

  &.partners {
    @media screen and (max-width: 767px) {
    }
  }

  &__contents {
    display: block;
    //margin-top: 83px;
    position: relative;
    width: 100%;

    .title {
      color: $white;
      font-size: 46px;
      font-weight: 700;
      letter-spacing: 1.84px;
      line-height: 1;
      margin: 0 auto;
      position: relative;
      width: fit-content;
      z-index: 1;

      @media screen and (max-width: 767px) {
        font-size: 37px;
        letter-spacing: 0.025em;
      }

      &:before {
        background: $t-green;
        bottom: 7px;
        content: '';
        height: 3px;
        position: absolute;
        width: 100%;
        z-index: -1;

        @media screen and (max-width: 767px) {
          bottom: 3px;
        }
      }

      &-portfolio {
        //margin-top: 20px;

        @media screen and (max-width: 767px) {
          margin-top: 0px;
        }
      }
    }
  }

  .caption-partners {
    display: none !important;
  }

  .caption-portfolio {
    bottom: 2px;
    color: $white;
    display: inline-block;
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 0.039em;
    line-height: 1;
    position: absolute;
    right: 0;
    width: fit-content;

    @media screen and (max-width: 767px) {
      bottom: -3px;
      font-weight: 400;
      letter-spacing: 0.075em;
      line-height: 1.55;
      right: 25px;
    }
  }
}

/* ロゴ一覧 */
.logo-list {
  display: grid;
  grid-template-columns: repeat(4, 245px);
  justify-content: center;
  grid-gap: 10px;
  margin-top: 34px;
  width: 100%;

  @media screen and (max-width: 767px) {
    margin: 31px auto 0;
    grid-template-columns: repeat(2, 160px);
    grid-gap: 4px;
  }

  &__item {
    background: #ffffff;
    border-radius: 10px;
    display: block;
    height: 133px;

    @media screen and (max-width: 767px) {
      border-radius: 5px;
      height: 116px;
    }

    .link-box {
      display: block;
      height: 100%;
      width: 100%;
      padding: 5% 12.5%;
      box-sizing: border-box;

      &:hover {
        opacity: 0.7;
      }
    }

    .img-box {
      align-items: center;
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
      @media screen and (max-width: 767px) {
        width: 90%;
      }

      .logo {
        max-width: 100%;
        max-height: 100%;

        // @media screen and (max-width: 767px) {
        //   width: 90%;
        // }
      }
    }
  }

  &__portfolio {
    margin-top: 35px;
    margin-bottom: 35px;

    @media screen and (max-width: 767px) {
      margin-top: 32px;
      margin-bottom: 32px;
    }

    .logo-list__item {
      &:first-child {
        .img-box {
          .logo {
            @media screen and (max-width: 767px) {
            }
          }
        }
      }

      &:last-child {
        @media screen and (max-width: 767px) {
          grid-column-start: unset;
          grid-column-end: unset;
        }
      }
    }
  }
}
