@import './variables';

#root {
  & > div.container-people,
  & > div.container-work,
  & > div.container-sdgs {
    z-index: 3;
    width: 100%;

    &.enter,
    &.exit {
      position: fixed;
      transition: height $transition-duration cubic-bezier(0, 0.55, 0.45, 1);
      bottom: 0;
      overflow: hidden;
      transform: translate3d(0, 0, 0);
      & > div {
        position: absolute;
        bottom: 0;
      }
    }

    &.enter {
      height: 0;
      & > div {
        height: 100vh;
      }
    }
    &.enter-active {
      height: 100vh;
      //画面遷移中のみ、メニューを固定表示にする
      .header-contents {
        position: absolute !important;
      }
    }

    &.exit {
      height: 100vh;
      & > div {
        height: 100vh;
      }
    }
    &.exit-active {
      height: 0;
      //画面遷移中のみ、メニューを固定表示にする
      .header-contents {
        position: absolute !important;
      }
    }

    &.enter-done {
    }
  }
}
