@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/lines.scss';

.l-sm {
  display: none !important;

  @media screen and (max-width: 767px) {
    display: block !important;
  }
}

.YuGothic {
  font-family: 'Yu Gothic', '游ゴシック', YuGothic, '游ゴシック体', -apple-system;
}

/* ファーストビュー */
.item-firstview {
  background-color: #eff2f5;
  display: flex;
  justify-content: center;
  width: 100%;

  @media screen and (min-width: 768px) {
    align-items: center;
  }

  video {
    position: fixed;
    top: calc(50% - (700px / 2));
    left: calc(50% - (1280px / 2));
    width: 1280px;
    height: 700px;
    z-index: 10;
  }

  .sp_video {
    top: 0;
    left: 0;
    width: 100%;
  }

  img {
    position: fixed;
    top: calc(50% - (700px / 2));
    left: calc(50% - (1280px / 2));
    width: 1280px;
    height: 700px;
    z-index: 1;
  }

  .video-sp_img {
    left: 0;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    // left: calc(50% - (375px / 2));
    height: 100%;
    width: auto;
  }

  .l-container {
    display: flex;
    height: 700px;
    justify-content: space-between;
    position: fixed;
    top: calc(50% - (700px / 2));
    width: 1182px;
    z-index: 10;
    @media screen and (max-width: 767px) {
      display: block;
      margin: 0px auto;
      top: 0;
      width: 86%;
      height: 100%;
    }
  }

  &__lines {
    @include lines;
  }

  &__title {
    display: block;
    height: 100%;
    width: 482px;

    @media screen and (max-width: 767px) {
      display: block;
      height: unset;
      width: 100%;
    }

    .title {
      background: $white;
      border-bottom: 4px solid #7fe5d7;
      color: #000;
      display: inline;
      font-size: 56px;
      font-weight: 700;
      line-height: 1.7;

      @media screen and (max-width: 767px) {
        font-size: 40px;
        line-height: 1.55;
      }

      /*.bg-title {
          background:$white;
          position: relative;
          z-index: 2;
          &:before {
            background: 7;
            bottom: 0;
            content: '';
            height: 4px;
            position: absolute;
            width: 100%;
            z-index: 0;
          }
          &:last-child {
            &:before {
              width: 100%;
              z-index: 0;
            }
          }
        }*/
    }

    .caption {
      background: $white;
      color: #000;
      display: block;
      font-family: Helvetica;
      font-size: 12px;
      font-weight: 900;
      letter-spacing: 0.13em;
      margin-top: 14px;
      width: fit-content;

      @media screen and (max-width: 767px) {
        font-size: 9px;
        letter-spacing: unset;
        margin-top: 15px;
      }
    }
  }

  .option-link {
    display: block;
    display: block;
    position: absolute;
    top: 70%;

    @media screen and (max-width: 767px) {
      top: 43%;
      opacity: 0;
      transition: opacity 400ms ease;

      &.enable {
        opacity: 1;
      }
    }
    @media screen and (min-width: 768px) {
      opacity: 0;
      transition: opacity 400ms ease;

      &.enable {
        opacity: 1;
      }
    }

    &__item {
      align-items: center;
      background: #00ccaf;
      border-radius: 30px;
      color: #fff;
      display: inline-flex;
      font-size: 14px;
      font-weight: 700;
      height: 50px;
      justify-content: center;
      width: 220px;
      cursor: pointer;
      opacity: 0;

      @media screen and (max-width: 767px) {
        width: 222px;
      }
    }
  }

  /* 右側 */

  &__item {
    display: block;
    position: relative;
    width: 590px;
    opacity: 0;
    transition: opacity 0.3s;
    @media screen and (max-width: 767px) {
      width: 100%;
      top: 43%;
    }

    .img-box {
      align-items: center;
      display: flex;
      height: 356px;
      justify-content: center;
      position: absolute;
      left: 6.25vh;
      top: -3.7vh;
      width: 459px;

      @media screen and (max-width: 767px) {
        height: 178px;
        left: unset;
        right: -2vh;
        top: 9vh;
        width: 232px;
      }

      &__img {
        height: 100%;
        width: 100%;
      }
    }

    .text-box {
      background: $white;
      border-top: 1px solid rgba(0, 0, 0, 0);
      border-bottom: 1px solid rgba(0, 0, 0, 0);
      display: block;
      position: relative;
      top: 34%;
      font-weight: bold;
      text-align: justify;

      @media screen and (max-width: 767px) {
        border: none;
        padding-left: 20px;
        padding-top: 22.5px;
        padding-bottom: 40.5px;
        top: 43%;
      }

      &__text {
        color: #000;
        font-size: 30px;
        //font-family: YuGo;
        letter-spacing: 3px;
        line-height: 1.77;
        position: relative;
        margin-top: -12px;
        margin-bottom: -12px;

        @media screen and (max-width: 767px) {
          font-size: 16px;
          letter-spacing: unset;
          line-height: 1.65;
          padding-left: 10px;

          &:not(:first-child) {
            padding-left: 0;
            margin-top: 34px;
          }

          .space {
            padding-left: 8px;
          }
        }
      }
    }
  }

  /* ブロック内scroll */
  .scroll-box {
    bottom: 0;
    display: block;
    height: 72px;
    left: 48px;
    position: absolute;
    z-index: 11;
    opacity: 0;
    transition: opacity 0.3s;

    @media screen and (max-width: 767px) {
      height: 65px;
      left: 23px;
    }

    &__text {
      font-size: 10px;
      font-weight: 800;
      display: block;
      height: 100%;
      padding-left: 6px;
      position: absolute;
      writing-mode: vertical-lr;

      .bar {
        background: #00ffb4;
        content: '';
        left: 2px;
        position: absolute;
        width: 2px;
        bottom: 0;
        animation-name: move;
        animation-duration: 2s;
        animation-iteration-count: infinite;
        animation-timing-function: cubic-bezier(0.9, 0.15, 0.05, 1);
      }
      @keyframes move {
        0% {
          top: 0;
          height: 0%;
        }
        29% {
          height: 100%;
        }
        70% {
          bottom: 0;
          height: 100%;
        }
        100% {
          bottom: 0;
          height: 0%;
        }
      }
    }
  }
  .show {
    opacity: 1;
    transition: opacity 0.5s;
  }
}
