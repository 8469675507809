@import '../common/function.scss';
@import '../common/variables.scss';
@import '../common/util.scss';

body {
  font-family: 'Montserrat', 'ゴシックMB101', '游ゴシック体', YuGothic, '游ゴシック Medium',
    'Yu Gothic Medium', '游ゴシック', 'Yu Gothic', sans-serif;
}

.Top {
  .title-box {
    &__blue {
      .title,
      .subTitle {
        color: $white;
      }
    }

    &__gray {
      .title,
      .subTitle {
        color: $black;
      }
    }

    &__contents {
      .title {
        font-size: 90px;
        font-weight: 700;
        letter-spacing: 0.85px;
        margin: 0px auto;
        position: relative;
        text-align: center;
        width: fit-content;
        z-index: 2;

        &:before {
          background: #7fe5d7;
          content: '';
          height: 6px;
          left: -1px;
          bottom: 18px;
          position: absolute;
          width: 100%;
          z-index: -1;
        }

        &.case {
          padding-top: 0;
        }

        &.partners {
          margin-right: 5px;
          top: -2px;

          &:before {
            left: 6px;
            width: 99%;
          }
        }

        &.team {
          color: #000;
        }

        &.portfolio {
          letter-spacing: 0.3px;
          margin-top: -4px;
        }
      }

      .subTitle {
        font-size: 18px;
        font-weight: 700;
        letter-spacing: 0.05em;
        margin-top: 22px;
        text-align: center;

        &.portfolio {
          margin-left: 30px;
        }
      }
    }
  }

  .item-team {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    .l-container {
      display: block;
      position: inherit;
      width: 1182px;
    }
  }
  .clear {
    opacity: 0;
  }
}
