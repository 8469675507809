@import '../common/function.scss';
@import '../common/variables.scss';

.team-list {
  display: block;
  margin-top: 50px;
  position: relative;
  width: 100%;

  @media screen and (max-width: 767px) {
    margin-top: 35px;
  }

  /* デザインA */

  &.teamA,
  &.teamC {
    .team-list__item {
      &--left {
        .team-list__item--profile {
          position: absolute;
          right: 0;

          @media screen and (max-width: 767px) {
            position: relative;
          }

          .name-box {
            // padding: 5px 31px 0 21px;

            @media screen and (max-width: 767px) {
              // padding: 41px 22px;
            }
          }

          .profile-box__position {
            // padding: 57px 23px 35px;

            @media screen and (max-width: 767px) {
              padding: 32px 14px 31px 26px;
            }
          }

          .profile-box__text {
            // padding: 35px 32px;

            @media screen and (max-width: 767px) {
              padding: 36px 18px 24px 25px;
            }
          }
        }

        .team-list__item--img {
          position: inherit;
          left: 0;

          @media screen and (max-width: 767px) {
            left: unset;
            position: absolute;
            right: 0;
          }
        }
      }
    }
  }

  /* デザインB */
  &.teamB {
    @media screen and (max-width: 767px) {
      margin-top: 24px;
    }

    .team-list__item {
      &--profile {
        .profile-box {
          &__position {
            // padding: 32px 27px 35px;

            @media screen and (max-width: 767px) {
              height: 173px;
              padding: 0 29px;
            }
          }
        }
      }

      &--left {
        .team-list__item--profile {
          position: absolute;
          right: 0;

          @media screen and (max-width: 767px) {
            position: relative;
          }

          .name-box {
            // padding: 0 27px;

            @media screen and (max-width: 767px) {
              // padding: 38px 18px 52px 22px;
            }
          }

          .profile-box__text {
            // padding: 25px 30px;

            @media screen and (max-width: 767px) {
              padding: 21px 30px 30px 27px;
            }
          }
        }

        .team-list__item--img {
          position: inherit;
          left: 0;

          @media screen and (max-width: 767px) {
            left: unset;
            position: absolute;
            right: 0;
          }
        }
      }

      &--right {
        .team-list__item--profile {
          .name-box {
            // padding: 2px 27px 0 24px;

            @media screen and (max-width: 767px) {
              // padding: 36px 30px 24px 21px;
            }

            .position {
              @media screen and (max-width: 767px) {
                margin-top: 10px;
              }
            }
          }

          .profile-box {
            &__text {
              align-items: center;
              // padding: 25px 30px;

              @media screen and (max-width: 767px) {
                padding: 20px 24px 24px 25px;
              }
            }
          }
        }
      }
    }
  }

  &__item {
    display: flex;
    height: 296px;
    justify-content: space-between;
    position: relative;
    width: 100%;

    @media screen and (max-width: 767px) {
      flex-direction: column;
      height: auto;
      padding-top: 38px;
      position: relative;
      width: 100%;
    }

    &:not(:first-child) {
      margin-top: 20px;

      @media screen and (max-width: 767px) {
        margin-top: 0px;
      }
    }

    &--profile {
      display: block;
      width: 877px;

      @media screen and (max-width: 767px) {
        order: 2;
        width: 100%;
      }

      .name-box {
        align-items: center;
        background: $white;
        display: flex;
        height: 80px;
        justify-content: space-between;
        padding: 2px 27px 0 30px;
        width: 100%;
        br {
          display: none;
        }

        @media screen and (max-width: 767px) {
          display: flex;
          justify-content: center;
          flex-direction: column;
          height: 160px;
          padding: 0 20px;
          width: calc(100% / 2 - 3px);
          br {
            display: inline;
          }
        }

        .name,
        .position {
          color: #000;
          font-weight: 700;

          @media screen and (max-width: 767px) {
            width: 100%;
          }
        }

        .name {
          font-size: 40px;
          letter-spacing: 0.1em;

          @media screen and (max-width: 767px) {
            font-size: 28px;
            letter-spacing: 1.58px;
          }
        }

        .position {
          font-size: 15px;
          letter-spacing: 0.045em;

          @media screen and (max-width: 767px) {
            font-size: 14px;
            letter-spacing: 0.1em;
            margin-top: 8px;
            padding-left: 5px;
          }
        }
      }

      .profile-box {
        display: flex;
        flex-wrap: wrap;
        height: 212px;
        justify-content: space-between;
        margin-top: 4px;
        width: 100%;

        @media screen and (max-width: 767px) {
          display: block;
          height: auto;
          margin-top: 4px;
        }

        &__position,
        &__text {
          background: $white;
        }

        &__position {
          display: flex;
          // align-items: center;
          justify-content: center;
          flex-direction: column;
          padding: 0 33px;
          width: 360px;
          &.empty{
            @media screen and (max-width: 767px) {
              display:none;
            }
          }

          @media screen and (max-width: 767px) {
            padding: 20px 18px 21px 25px;
            width: 100%;
          }

          .text {
            color: #000;
            font-size: 12px;
            font-weight: 400;
            letter-spacing: 0.072em;
            line-height: 2;
            white-space: pre-wrap;

            @media screen and (max-width: 767px) {
              line-height: 1.85;
            }
          }
        }

        &__text {
          padding: 15px 25px;
          width: 513px;
          justify-content: center;
          display: flex;
          flex-direction: column;

          @media screen and (max-width: 767px) {
            min-height: 241px;
            margin-top: 6px;
            padding: 20px 24px 24px 23px;
            width: 100%;
          }

          .text {
            color: #000;
            font-size: 15px;
            font-weight: 400;
            line-height: 2;

            @media screen and (max-width: 767px) {
              font-size: 14px;
              line-height: 28px;
            }
          }
        }
      }
    }

    &--img {
      display: block;
      width: 296px;

      @media screen and (max-width: 767px) {
        height: 160px;
        order: 1;
        overflow: hidden;
        position: absolute;
        right: 0;
        width: 49%;
      }

      .img-box {
        display: block;
        height: 100%;
        overflow: hidden;
        width: 100%;

        .img {
          height: 100%;
          width: 100%;

          @media screen and (max-width: 767px) {
            object-fit: cover;
          }
        }
      }
    }

    &--left {
      .team-list__item--profile {
        position: absolute;
        right: 0;

        @media screen and (max-width: 767px) {
          position: relative;
        }

        .name-box {
          // padding: 0 30px 0 25px;

          @media screen and (max-width: 767px) {
            // padding: 52px 18px;
          }
        }

        .profile-box__position {
          // padding: 57px 23px 35px;

          @media screen and (max-width: 767px) {
            padding: 32px 14px 31px 26px;
          }
        }
      }

      .team-list__item--img {
        position: inherit;
        left: 0;

        @media screen and (max-width: 767px) {
          left: unset;
          position: absolute;
          right: 0;
        }
      }
    }
  }
}
