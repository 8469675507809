.company-profile {

  &_inner {
    padding-top: 155px;
    padding-bottom: 150px;
    text-align: center;

    @media screen and (max-width: 767px) {
      padding-top: 68px;
      padding-bottom: 74px;
    }

    .title {
      h2 {
        display: inline-block;
        font-size: 46px;
        font-weight: 700;
        line-height: 0.45;
        letter-spacing: 1.6px;

        @media screen and (max-width: 767px) {
          display: inline;
          line-height: 42.5px;
          font-size: 38px;
          background: linear-gradient(transparent 90%, #7fe5d7 90%) no-repeat;
          background-position: 100% 55%;
          background-size: 100% 28px;
        }

        &::after {
          content: '';
          display: block;
          background: #7fe5d7;
          height: 6px;
          margin-top: 4px;
          margin-left: 10px;
          width: 96%;

          @media screen and (max-width: 767px) {
            display: none;
          }
        }
      }

      span {
        display: block;
        margin-top: 20px;
        font-size: 16px;
        font-weight: bold;
        letter-spacing: 1.6px;

        @media screen and (max-width: 767px) {
          font-size: 14px;
          margin-top: 14px;
        }
      }
    }

    .contents {
      display: inline-block;
      margin-top: 48px;

      @media screen and (max-width: 767px) {
        margin-top: 35px;
        padding: 0;
        width: 86%;
      }

      .list {
        display: flex;

        @media screen and (max-width: 767px) {
          min-height: 60px;
          line-height: unset;
        }

        &:not(:first-child) {
          margin-top: 3px;

          @media screen and (max-width: 767px) {
            margin-top: 6px;
          }
        }

        &-title {
          align-items: center;
          background-color: #ffffff;
          display: flex;
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 1.2px;
          margin-right: 3px;
          padding-left: 10px;
          width: 70px;
          line-height: 47px;

          @media screen and (max-width: 767px) {
            align-items: center;
            display: flex;
            padding: 0 10px;
            font-size: 11px;
            width: 86px;
          }
        }

        &-text {
          font-weight: 500;
          text-align: left;
          background-color: #ffffff;
          width: 705px;
          font-size: 16px;
          letter-spacing: 1.6px;
          padding: 24px 19px;
          line-height: 180%;
          white-space: pre-wrap;

          @media screen and (max-width: 767px) {
            align-items: center;
            display: flex;
            font-size: 12px;
            letter-spacing: 1.4px;
            padding: 15px;
            width: 100%;
          }
          table {
            tr {
              td {
                &:first-child {
                  width: 7em;
                }
              }
            }
          }
        }
      }
    }
  }
}
